import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Box, Button } from "@mui/material";
import Loading from "./Loading";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

const EmailVerify = () => {
  const [validurl, setValidUrl] = useState(null);

  const [error, setError] = useState(null);
  const { id, token } = useParams();

  useEffect(() => {
    const verifyEmailUrl = async () => {
      try {
        setError("");
        const url = `${process.env.REACT_APP_API_URL}/api/users/${id}/verify/${token}`;
        const data = await axios.get(url);
        if (data.status == 200) {
          setValidUrl(true);
        }
      } catch (error) {
        console.log(error);
        setValidUrl(false);
        setError(error.message || "An error occurred while verifying email");
      }
    };
    verifyEmailUrl();
  }, [id, token]);
  console.log("validurl", validurl);
  return (
    <Box
      sx={{
        height: "88vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {validurl == null ? (
        <Loading />
      ) : (
        <>
          {validurl ? (
            <div
              style={{
                padding: "1px",
                borderRadius: "2px",
                marginInline: "2.2em",
                gap: "1em",
                textAlign: "center",
              }}
            >
              <VerifiedOutlinedIcon
                sx={{
                  color: "#33ff33",
                  fontSize: "70px",
                  borderRadius: "50%",
                }}
              />
              <h1> Email Verified Successfully </h1>
              <Link to="/auth">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#33ff33",
                    marginTop: "1em",
                    color: "white",
                    fontSize: "20px",
                    paddingInline: "70px",
                    "&hover": { backgroundColor: "black" },
                    // marginTop: "1.5em",
                  }}
                  fullWidth
                  type="submit"
                >
                  Log In
                </Button>
              </Link>
            </div>
          ) : (
            <div>
              <h1>Verification Failed</h1>
              <p>{error || "An error occurred while verifying email"}</p>
            </div>
          )}
        </>
      )}
    </Box>
  );
};

export default EmailVerify;
