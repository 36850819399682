import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import AdminTable from "./AdminTable";
import { DataContext } from "../utils/ContextProider";
import AddAdmin from "./AddAdmin";

const Settings = () => {
  const { isAdmin } = useContext(DataContext);

  return (
    <Box
      sx={{
        height: { xs: "100vh", sm: "80vh", md: "79.5vh", lg: "69vh" },
        display: "flex",
        flexDirection: {
        xs: "column", sm: "row",
        },
        alignItems: "center",
        justifyContent: { xs: "start", sm: "center", md: isAdmin ? "center" : "right", lg: isAdmin ? "space-evenly" : "space-evenly" },
        gap: { xs: 2, sm: 2, md: 5, lg: 8 },
        pt: { xs: "1em", sm: "0" },
        mb: { xs: "2em", sm: "0" },
      // border:"2px solid red",
        width:{ md:"74vw",lg:"82vw"},
        ml: { md: isAdmin ? "3.8em" : "0", lg: isAdmin ? "3em" : "0"},
      }}
    >

      <AddAdmin />
      <AdminTable />

    </Box>
  );
};

export default Settings;
