import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Login from "./Login";
import Signup from "./SignUp";
import { Route, Routes, useLocation } from "react-router-dom";
import ForgetPassword from "./ForgetPassword";
import EmailVerify from "./EmailVerify";
import ResetPassword from "./ResetPassword";
import Loading from "./Loading";
import { DataContext } from "../utils/ContextProider";

const LoginPage = () => {
  const { currentColor, isLoading, setIsLoading } = useContext(DataContext);

  return (
    <Box
      sx={{
        height: "82vh",
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row" },
        justifyContent: {
          xs: "space-evenly",
          sm: "space-evenly",
          lg: "space-between",
          xl: "space-between",
        },

        alignItems: "center",
     
      }}
    >
      <Box sx={{ paddingLeft:{md:"3.4em" , lg:"2.75em"}}}>
        <Typography sx={{ typography: { xs: "h5", sm: "h4", md: "h2" } }}>
          Welcome to <span style={{ color: "#1877f2" }}>Expense</span> App
        </Typography>
      </Box>

      <Box sx={{ paddingRight:{md:"3.5em" , lg:"3.05em"}}}>

      <Box
        sx={{
          minHeight: "22em",
          width: { xs: "20.5em", sm: "30em", md: "25em", lg: "25em" },
          bgcolor: "whitesmoke",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px ",
          paddingY: ".8em",
         
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
        )}
</Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
