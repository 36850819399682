import axios from "axios";
import React, { useContext, useState } from "react";
import { DataContext } from "../utils/ContextProider";
import Loading from "./Loading";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

const ForgetPassword = () => {
  const { currentColor } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [successMessage, setsuccessMessage] = useState(false);

  const handleReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMsg("");
    setError("");
    const emailData = email + "@dyotis.com";
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/reset`;
      const res = await axios.post(url, { emailData });

      if (res.status == 200) {
        setsuccessMessage(true);
      }
      setMsg(res.data.message);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "@") {
      event.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        color: "black",
      }}
    >
      <Box
        sx={{
          width: { xs: "46%", sm: "70%", lg: "27%" },
          height: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
          padding: "6em 4em",
          borderRadius: "5px",
          gap: "0.5em",
          textAlign: "center",
      
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {" "}
            {successMessage ? (
              <>
                <VerifiedOutlinedIcon
                  sx={{
                    color: "#33ff33",

                    fontSize: "70px",
                    borderRadius: "50%",
                  }}
                />
                <h2>Please reset your password </h2>
                <Box
                  sx={
                    msg
                      ? {
                          width: "100%",
                          p: "15px",
                          m: "5px 0px",
                          fontSize: "14px",
                          bgcolor: "#5cdd5c",
                          color: "white",
                          borderRadius: "5px",
                          textAlign: "center",
                        }
                      : null
                  }
                >
                  {msg && <Box>{msg}</Box>}
                </Box>
              </>
            ) : (
              <Box>
                <h2>Reset your password</h2>
                <Typography sx={{ marginBottom: "1em" }}>
                  To reset your password, enter the email address you use to log
                  in.
                </Typography>
                <form
                  onSubmit={handleReset}
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <FormControl variant="outlined" sx={{ width: "100%" }}>
                    <InputLabel>Email</InputLabel>
                    <OutlinedInput
                      label="Email"
                      onChange={(e) => handleChange(e)}
                      name="email"
                      type="text"
                      endAdornment={
                        <InputAdornment position="end">
                          @dyotis.com
                        </InputAdornment>
                      }
                      //   sx={{width:"28em"}}
                      onKeyDown={handleKeyDown}
                      value={email}
                      required
                    />
                  </FormControl>

                  <Button
                    variant="contained"
                    style={{
                      //   backgroundColor: "#1877f2",
                      marginTop: "1em",
                      color: "white",
                      fontSize: "20px",
                      paddingInline: "70px",

                      "&hover": { backgroundColor: "black" },
                    }}
                    fullWidth
                    type="submit"
                  >
                    LogIn
                  </Button>
                  <Box
                    sx={
                      error || msg
                        ? {
                            p: "15px",
                            mt: "1em",
                            fontSize: "14px",
                            bgcolor: msg ? "#5cdd5c" : "#f34646",
                            color: "white",
                            borderRadius: "5px",
                            textAlign: "center",
                          }
                        : null
                    }
                  >
                    {error && <Box>{error}</Box>}
                    {msg && <Box>{msg}</Box>}
                  </Box>
                </form>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection:{xs:"column", sm:"row" , lg:"row"},
                    justifyContent: {xs:"center",sm:"space-evenly",lg:"space-evenly"},
                    alignItems: "center",
                    mt: "1em",
                
                  }}
                >
                  <Typography>Never mind!</Typography>
                  <Link to="/auth">
                    <Button
                      sx={{
                        color: "#1877f2",
                        textDecoration: "underline",
                        textTransform: "none",
                      }}
                    >
                      Take me back to login
                    </Button>
                  </Link>
                </Box>
              </Box>
            )}{" "}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ForgetPassword;
