import { Box, Button, Grid, Stack, Table, TextField, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import EnhancedTable from "./EnhancedTable";

const ListComponents = ({ list, setList }) => {
 
  
  const deleteItem = async (productId) => {
    const respose = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products/${productId}`,
      {
        method: "DELETE",
      }
    ).then((res) => res);
    setList((prev) => prev.filter((item) => item.id !== productId));
  };

  return (

    <Stack
      sx={{ backgroundColor: "#",
       borderRadius: "3px", width:"100%"}}
       >
       <EnhancedTable list={list} setList={setList} />
    </Stack>
  );
};

export default ListComponents;
