import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../utils/ContextProider";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Loading from "./Loading";

const ResetPassword = () => {
  const { currentColor } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();
  const [data, setData] = useState({ password: "", confirm_password: "" });
  const [validurl, setValidUrl] = useState(true);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      try {
        const url = `${process.env.REACT_APP_API_URL}/api/reset/password/${token}`;
        const res = await axios(url);
        setValidUrl(true);
      } catch {
        setValidUrl(false);
      } finally {
        setIsLoading(false);
      }
    };
    checkAuth();
  }, [token]);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (data.password !== data.confirm_password) {
      setError("Password is not matched");
      return;
    }
    setIsLoading(true);
    try {
      // console.log(data.password);
      const url = `${process.env.REACT_APP_API_URL}/api/reset/set_new_password/${token}`;
      const res = await axios.post(url, data);
      if (res.status == 200) {
        // console.log("res", res);
        setMsg(res.data.message);
      }
      // localStorage.setItem("token", res.data);
      // window.location = "/";
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {validurl ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
            color: "black",
           
            
          }}
        >
          <Box
            sx={{
              width:{xs:"90%",sm:"72%", lg:"27%"},
              height: "70%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              padding: "1em 4em 2em 4em",
              borderRadius: "5px",
              gap: "0.5em",
           
             textAlign:"center"
            }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {msg ? (
                  <>
                    <VerifiedOutlinedIcon
                      sx={{
                        color: "#33ff33",

                        fontSize: "70px",
                        borderRadius: "50%",
                      }}
                    />
                    <h2>You password has been changed</h2>
                    <Box
                      sx={
                        msg
                          ? {
                              width: "100%",
                              p: "15px",
                              m: "5px 0px",
                              fontSize: "14px",
                              bgcolor: "#5cdd5c",
                              color: "white",
                              borderRadius: "5px",
                              textAlign: "center",
                            }
                          : null
                      }
                    >
                      {msg && <Box>{msg}</Box>}
                    </Box>
                  </>
                ) : (
                  <>
                  
                    <h2>Reset your password</h2>
                    <Typography sx={{ marginBottom: "1em" ,width:"90%" }}>
                      Enter your new password. After confirming, you will be
                      asked to log in again.
                    </Typography>
                   
                    <form
                      onSubmit={handleSubmit}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        width:"95%",
                        gap: "1em",
                      }}
                    >
                      <FormControl variant="outlined">
                        <InputLabel>Password</InputLabel>
                        <OutlinedInput
                          label="Password"
                          name="password"
                          onChange={handleChange}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          fullWidth
                          autoComplete="on"
                          value={data.password}
                          required
                        />
                      </FormControl>
                      <FormControl variant="outlined">
                        <InputLabel>Confirm Password</InputLabel>
                        <OutlinedInput
                          label="confirm_password"
                          name="confirm_password"
                          onChange={handleChange}
                          type={showConfirmPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          fullWidth
                          autoComplete="on"
                          value={data.confirm_password}
                          required
                        />
                      </FormControl>

                      <Button
                        variant="contained"
                        style={{
                          color: "white",
                          fontSize: "20px",
                          paddingInline: "70px",
                          "&hover": { backgroundColor: "black" },
                          marginTop: "1em",
                        }}
                        fullWidth
                        type="submit"
                      >
                        Log In
                      </Button>
                      <Box
                        sx={
                          error || msg
                            ? {
                                p: "15px",

                                fontSize: "14px",
                                bgcolor: msg ? "#5cdd5c" : "#f34646",
                                color: "white",
                                borderRadius: "5px",
                                textAlign: "center",
                              }
                            : null
                        }
                      >
                        {error && <Box>{error}</Box>}
                        {msg && <Box>{msg}</Box>}
                      </Box>
                    </form>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography>Never mind!</Typography>
                      <Link to="/auth">
                        <Button
                          sx={{ color: "#1877f2", textDecoration: "underline",textTransform:"none" }}
                        >
                          Take me back to login
                        </Button>
                      </Link>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "88vh",
          }}
        >
          <h2>You are not authorize</h2>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
