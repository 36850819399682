import React, { useContext, useState } from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { DataContext } from "../utils/ContextProider";
import { sidebarCategories } from "../utils/constant";
import { useNavigate } from "react-router-dom";

import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const Sidebar = () => {
  const {
    currentColor,
    selectedCategory,
    setselectedCategory,
    CollapsableIcon,
    setCollapsableIcon,
  } = useContext(DataContext);
  const navigate = useNavigate();

  const [visibility, setvisibility] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const changeRoute = (category, url) => {
    setselectedCategory(category);
    localStorage.setItem("selectedCategory", category);
    navigate(url);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        bgcolor: "rgb(224 224 210)",
        position: "fixed",
        zIndex: "1000",
        top: "0",
        left: "0",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        // mt:{lg:"0.2em"},
       
      }}
      onMouseEnter={async () => {
        await new Promise((resolve) => setTimeout(() => resolve(), 200));
        setIsHovered(true);
      }}
      onMouseLeave={async () => {
        await new Promise((resolve) => setTimeout(() => resolve(), 200));
        setIsHovered(false);
      }}
    >
      {isHovered && (
        <ArrowCircleLeftOutlinedIcon
          sx={{
            fontSize: "30px",
            color: "gray",
            display: { xs: "none", sm: "none", md: "inline-block" },
            bgcolor: "white",
            borderRadius: "100%",
            border: "1px solid lightgray",
            cursor: "pointer",
            transform: CollapsableIcon ? "rotate(180deg)" : "",
            top: "2.5em",
            position: "fixed",
            left: CollapsableIcon ? "2em" : "5.5em",
            zIndex: "10000",
          }}
          onClick={(e) => setCollapsableIcon((prev) => !prev)}
        />
      )}
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "inline-block" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: CollapsableIcon ? "space-evenly" : " ",
            gap: CollapsableIcon ? "0" : "-1.5em",
            mt: ".7em",
            // px:"1em"
          }}
        >
          <IconButton
            size="large"
            edge="center"
            color="inherit"
            aria-label="open drawer"
            sx={{ color: currentColor.backgroundColor }}
          >
            <AddShoppingCartIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              color: currentColor.backgroundColor,
              display: CollapsableIcon && "none",
            }}
          >
            Expense App
          </Typography>
        </Box>

        <Box sx={{ height: "2em" , }}></Box>
        {sidebarCategories.map((category, idx) => (
          <Link
            key={idx}
            onClick={() => changeRoute(category.name, category.url)}
            sx={{
              margin: "1em",
              backgroundColor:
                selectedCategory === category.name && currentColor.buttonColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: 2,
              padding: "0.5em",
              color: selectedCategory === category.name ? "white" : "black",
              cursor: "pointer",
              textDecoration: "none",
              borderRadius: "7px",
              marginTop: "2em",
              border: "0.2px solid gray",
              transition: "background-color ease-in 0.2s",
              "&:hover": {
                backgroundColor:
                  selectedCategory === category.name
                    ? ""
                    : currentColor.tableHeadBackgroundColor,
              },
            }}
          >
            <span>{category.icon}</span>
            {!CollapsableIcon ? (
              <Typography
                sx={{
                  fontWeight: "600px",
                  display: visibility ? "" : "none",
                  fontSize: "18px",
                }}
              >
                {category.name}
              </Typography>
            ) : (
              ""
            )}
          </Link>
        ))}
      </Box>

      <Box
        sx={{
          position: "fixed",
          zIndex: "10",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          width: "100%",
          display: { xs: "flex", sm: "flex", md: "none" },
          justifyContent: "space-around",
          paddingBottom: "10px",
         
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="icon label tabs example"
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            display: "flex",
          

          }}
        >
          {sidebarCategories.map((category, idx) => (
            <Tab
              key={idx}
              label={category.name}
              aria-label="dashboard"
              sx={{
                color: "gray",
                display: "flex",
                fontSize: { xs: "12px", sm: "14px" },
          
                justifyContent: "center",
            alignItems:"center",
            margin: { xs: "0 .3em", sm: "0 4em" },
            marginRight:{ xs: "1.5em", sm: "5.2em" },
            // border:"2px solid red"
              
              }}
              icon={category.icon}
              onClick={() => changeRoute(category.name, category.url)}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default Sidebar;
