import React from "react";
import { Box, Button, Typography } from "@mui/material";
import expenseDashboard from "../Images/ex.png";

const Home = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: {xs:"space-evenly" , md:"space-between"},
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          height: "87vh",
  
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: {
              xs: "94vw",
              sm: "90vw",
              md: "70vw",
              lg: "49vw",
              xl: "49vw",
            },
            paddingTop: { xs: "5px", lg: "0" },
            color: "white",
            ml: { xs: "1em", sm: "1em", md: "3.45em", lg: "2.8em" },
            mt: { xs: ".2em", sm: "0em" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "orange",
              fontSize: {
                xs: "2em",
                sm: "3em",
                md: "2.2em",
                lg: "3.25em",
                xl: "3.25em",
              },
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "-.5px",
              textShadow: "none",
            }}
          >
            Empowering You to <br /> Control Your Finances.
          </Typography>

          <Typography
            sx={{
              width: {
                xs: "93.5%",
                sm: "95%",
                md: "90%",
                lg: "94%",
                xl: "94%",
              },
              margin: "15px 0px",
              fontSize: {
                xs: ".8em",
                sm: "1.2em",
                md: "1em",
                lg: "1em",
                xl: "1em",
              },
              textAlign: "justify",
              paddingRight: "1em",
            }}
          >
            Empower your financial decisions with our user-centric expense app.
            Take control of your expenses, set your own rules, and effortlessly
            manage your budget. Our feature-rich platform ensures a personalized
            experience, offering tailored insights and strategies for a seamless
            financial journey tailored to your unique needs.
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "2em",
              fontSize: ".63em",
              paddingLeft: { sm: "2px" },
              flexDirection:{xs:"column" , sm :"row"},
           paddingRight:{xs:"1.5em" , sm :"0"}
            }}
          >
            <Button
              variant="contained"
              sx={{
                fontWeight: "600",
                padding: "8px 8px",
                backgroundColor: "#f95b51",
                "&:hover": { bgcolor: "purple" },
              }}
            >
              Start A free trial
            </Button>
            <Button
              variant="outlined"
              sx={{
                fontWeight: "600",
                padding: { xs: "8px 8px", sm: "8px 8px", lg: "8px 8px" },
                color: "white",
                "&:hover": { bgcolor: "purple" },
              }}
            >
              Request a Demo
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: { xs: "90%", sm: "87.5%", md: "60%", lg: "40%", xl: "40%" },
            justifyContent: "center",
            alignItems: { xs: "start", lg: "center" },
            paddingRight: { md: "3.45em", xl: "3em" },
            paddingTop: { xs: ".8em", lg: "0" },
            paddingBottom: { xs: ".8em", lg: "0" },
          
          }}
        >
          <img
            src={expenseDashboard}
            alt="dashboard.."
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "10px",
             
            }}
          />
        </Box>
        
      </Box>
    </>
  );
};

export default Home;
