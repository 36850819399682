import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { DataContext } from "../utils/ContextProider";
// import { useMaterialUIController } from "context";

function AlertDelete({ isDelete, setIsDelete, dataToDelete }) {
  const { currentcolor } = useContext(DataContext);
  const handleClose = () => {
    setIsDelete(false);
  };

  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };

  //delete API

  const handleConfirmDelete = async () => {
    const accessToken = checkAccessTokenInCookie();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/deleteAdmin/${dataToDelete.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (response.ok) {
      setIsDelete(false);
    } else {
      // Handle error, e.g., show an alert or log the error
      console.error("Error deleting items:", response.statusText);
    }
  };

  return (
    <Box>
      <Dialog
        // fullWidth={fullWidth}
        maxWidth="sm"
        open={isDelete}
        onClose={handleClose}
     >
        {/* <DialogTitle textAlign="center"> </DialogTitle> */}
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width:{xs:"19.5rem" , sm :"30rem" , lg:"30rem"} , 
            paddingBottom: "20px",
            px:{xs:"2.5em" , sm :"0em",  lg:"0em"}
          
          }}
        >
          <Box sx={{ fontSize: "5rem", color: "red" }}>
            <ErrorOutlineIcon />
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            {" "}
            Do you want to Delete {dataToDelete.name}?
          </Typography>
        </Box>

        <DialogActions sx={{ backgroundColor: "#FFFFFF" }}>
          <Box mr={5} pb={2} display="flex" gap={2}>
            <Button
              variant="gradient"
              color="info"
              size="medium"
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
            <Button
              variant="gradient"
              color="light"
              size="medium"
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AlertDelete;
