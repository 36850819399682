import auth0 from "auth0-js";
import {
  AUTH_CLIENT_ID,
  AUTH_DOMAIN,
  AUTH0_USER_SCOPE,
  AUTH0_AUDIENCE,
  
} from "../../utils/config";

export const auth = new auth0.WebAuth({
    domain: AUTH_DOMAIN,
    clientID:AUTH_CLIENT_ID,
    scope:AUTH0_USER_SCOPE,
    audience : AUTH0_AUDIENCE,
    responseMode:'form-get'
})