import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "./services/auth0.services";
import {
  AUTH0_LOGIN_REDIRECT_URI,
  AUTH0_LOGIN_RESONSE_TYPE,
  AUTH0_REALM,
  AUTH0_USER_SCOPE,
  AUTH_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH_CLIENT_SECRET,
} from "../utils/config";
import { DataContext } from "../utils/ContextProider";
import { Link, useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loading from "./Loading";
import axios from "axios";

const Login = () => {
  const { currentColor } = useContext(DataContext);
  const [data, setData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };
  const handleKeyDown = (event) => {
    if (event.key === "@") {
      event.preventDefault();
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newData = { ...data, email: data.email + "@dyotis.com" };

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/auth`;
      
      const res = await axios.post(url, newData);
      setMsg(res.data.message);

      console.log(res.data.message, "ghgvh")

      console.log(res);

      const name = res?.data?.data?.firstName;
      const emailid = res.config.data;
      const Emailid = JSON.parse(emailid);
      const fullEmailId = Emailid.email;

      
      console.log(name);
      
      console.log(emailid);
      
      localStorage.setItem("emailid", fullEmailId);

      // (emailid.email)

      localStorage.setItem("username", name);

      console.log(name);

      if (res.status === 200) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);

        document.cookie = `access_token=${res.data.token}; expires=${expirationDate.toUTCString()}; path=/; secure`;
        navigate("/dashboard");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        console.log("Error", error.response.data.message);
        setError(error.response.data.message);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Unexpected error:", error);
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <h3 style={{ color: "black", opacity: "70%", fontSize: "1.5em" }}>
        Login
      </h3>

      {isLoading ? (
        <Loading />
      ) : (
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "1px",
            borderRadius: "2px",
            marginInline: "2.2em",
            gap: "1em",
          }}
        >
          <FormControl variant="outlined" sx={{ width: "100%" }}>
            <InputLabel>Email</InputLabel>
            <OutlinedInput
              label="Email"
              onChange={handleChange}
              name="email"
              type="text"
              endAdornment={
                <InputAdornment position="end">@dyotis.com</InputAdornment>
              }
              onKeyDown={handleKeyDown}
              fullWidth
              value={data.email}
              required
            />
          </FormControl>

          <FormControl variant="outlined" sx={{ width: "100%" }}>
            <InputLabel>Password</InputLabel>
            <OutlinedInput
              label="Password"
              name="password"
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              autoComplete="on"
              value={data.password}
              required
            />
          </FormControl>
          <Box
            sx={
              error || msg
                ? {
                  width: "80%",
                  p: "12px",
                  m: "5px 0px",
                  fontSize: "14px",
                  bgcolor: msg ? "#5cdd5c" : "#f34646",
                  color: "white",
                  borderRadius: "5px",
                  textAlign: "center",
                }
                : null
            }
          >
            {error && <Box>{error}</Box>}
            {msg && <Box>{msg}</Box>}
          </Box>
          <Button
            variant="contained"
            style={{
              //   backgroundColor: "#1877f2",

              color: "white",
              fontSize: "20px",
              paddingInline: "70px",
              "&hover": { backgroundColor: "black" },
              // marginTop: "1.5em",
            }}
            fullWidth
            type="submit"
          >
            Log In
          </Button>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "start",
              width: "100%",
            }}
          >
            <Link to="/forget_password">
              <Button
                sx={{
                  color: "#1877f2",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
              >
                Forget Password?
              </Button>
            </Link>
            <Link to="/auth/signup">
              <Button
                sx={{
                  color: "#1877f2",
                  textDecoration: "underline",
                  textTransform: "none",
                }}
              >
                Sign Up
              </Button>
            </Link>
          </Box>
        </form>
      )}
    </>
  );
};

export default Login;
