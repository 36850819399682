import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import img from "../Images/Dyotis_Logo_New_Gray.png";
import { Link, useLocation } from "react-router-dom";
import { DataContext } from "../utils/ContextProider";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CategoryIcon from "@mui/icons-material/Category";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";

const pages = [
  {
    name: "Products",
    icon: <CategoryIcon />,
    route: "products",
  },

  {
    name: "Pricing",
    icon: <CurrencyRupeeIcon />,
    route: "pricing",
  },
  {
    name: "Services",
    icon: <ManageAccountsIcon />,
    route: "services",
  },
  {
    name: "About Us",
    icon: <Diversity1Icon />,
    route: "about",
  },
];

const auth = [
  {
    name: "Sign In",
    icon: <PersonIcon />,
    route: "auth",
  },
  {
    name: "Sign Up",
    icon: <PersonAddAlt1Icon />,
    route: "auth/signup",
  },
];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const { isNavbarOpen, setIsnavbarOpen, currentColor } =
    React.useContext(DataContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [link, setLink] = React.useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e, field) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  React.useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signup") {
      setIsnavbarOpen(false);
    } else {
      setIsnavbarOpen(true);
    }
  }, []);

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          position: "relative",
          left: "1em",
          top: "0.5em",
          my: "2em",
        }}
      >
        <ListItemIcon>
          <ClearIcon />
        </ListItemIcon>
      </Box>
      <List>
        {pages.map((page, index) => (
          <ListItem key={index} disablePadding>
            <Link
              to={page.route}
              style={{ textDecoration: "none", color: "black", width: "100%" }}
            >
              <ListItemButton>
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {auth.map((page, index) => (
          <ListItem key={index} disablePadding>
            <Link
              to={page.route}
              style={{ textDecoration: "none", color: "black", width: "100%" }}
            >
              <ListItemButton>
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="static" sx={{ bgcolor: currentColor.backgroundColor ,}}>
      <Container maxWidth="xl" >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginX: { xs: "0em", sm: "1.7em", md: "2em", lg: "1.5em" },
            alignItems: "center",
            paddingY: "0.7em",
           
          }}
        >
          <Link to="/">
            <img src={img} height="55px" width="120px" alt="dyotis_logo" />
          </Link>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between]",
              alignItems: "center",
             
            }}
          >
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "flex", md: "none" },
             
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer("right", true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
                onOpen={toggleDrawer("right", true)}
              >
                {list("right")}
              </SwipeableDrawer>
              {/* <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{
                      fontWeight: "800",
                      width: "38vw",
                    }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
                {isNavbarOpen &&
                  auth.map((page) => (
                    <MenuItem
                      key={page}
                      sx={{
                        fontWeight: "800",
                      }}
                    >
                      <Link
                        to={page === "Sign In" ? "/auth" : "/auth/signup"}
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={(e) => setLink(page)}
                      >
                        <Typography textAlign="center">{page}</Typography>
                      </Link>
                    </MenuItem>
                  ))}
              </Menu> */}
            </Box>

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", md: "flex" },
                             
              }}
            >
              {pages.map((page) => (
                <Link
                  key={page.name}
                  to={`/${page.name.replace(/\s+/g, "").toLowerCase()}`}
                  onClick={(e) => handleCloseNavMenu(e, page)}
                  style={{
                   marginInline: 18.5,
                    color: "white",
                    display: "block",
                    textDecoration: "none",
                    fontSize: "1.2em",
                  }}
                >
                  {page.name}
                </Link>
              ))}
            </Box>

            {isNavbarOpen && (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                }}
              >
                <Link to="/auth">
                  <Button
                    variant="outlined"
                    sx={{
                      width:"9em",
                      fontSize: "1em",
                      color: "white",
                      marginRight: "0.7em",
                      marginLeft:"0.7em",
                      fontWeight: "600",
                      "&:hover": { bgcolor: "purple" },
                    }}
                  >
                    Sign In
                  </Button>
                </Link>
                <Link to="/auth/signup">
                  <Button
                    variant="contained"
                    sx={{
                      width: "10em",
                      fontWeight: "600",
                      fontSize: "1em",
                      backgroundColor: "#f95b51",
                      "&:hover": { bgcolor: "purple" },
                    }}
                  >
                    Get Started
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
