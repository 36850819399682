import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import FilterListIcon from "@mui/icons-material/FilterList";
import SaveIcon from "@mui/icons-material/Save";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { visuallyHidden } from "@mui/utils";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField,
  collapseClasses,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { categories } from "../utils/constant";
import ImageViewer from "./ImageViewer";
import PdfViewer from "./PdfViewer";
import ImageComponent from "./ImageComponent";
import { DataContext } from "../utils/ContextProider";
import Loading from "./Loading";
import { blue, green } from "@mui/material/colors";

import { useEffect } from "react";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// const headCells = [
//   {
//     id: "date",
//     numeric: false,
//     disablePadding: true,
//     label: "Date",
//   },
//   {
//     id: "name",
//     numeric: true,
//     disablePadding: false,
//     label: "Category",
//   },
//   {
//     id: "amount",
//     numeric: true,
//     disablePadding: false,
//     label: "bii_id ₹",
//   },
//   {
//     id: "remark",
//     numeric: true,
//     disablePadding: false,
//     label: "Remarks",
//   },
//   {
//     id: "attachment",
//     numeric: true,
//     disablePadding: false,
//     label: "Attachment",
//   },
//   {
//     id: "Actions",
//     numeric: true,
//     disablePadding: false,
//     label: "Actions",
//   },
//   // {
//   //   id: "edit",
//   //   numeric: true,
//   //   disablePadding: false,
//   //   label: "Edit",
//   // },
//   // {
//   //   id: "delete",
//   //   numeric: true,
//   //   disablePadding: false,
//   //   label: "Delete",
//   // },
// ];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    currentColor,
    isImageData,
    selectedData,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = !isImageData
    ? [
        {
          id: "date",
          numeric: true,
          disablePadding: true,
          label: "Date",
        },
        {
          id: "name",
          numeric: true,
          disablePadding: false,
          label: "Category",
        },
        {
          id: "amount",
          numeric: true,
          disablePadding: false,
          label: "Amount",
        },
        {
          id: "remark",
          numeric: true,
          disablePadding: false,
          label: "Remarks",
        },
        {
          id: "attachment",
          numeric: true,
          disablePadding: false,
          label: "Attachment",
        },
      ]
    : [
        {
          id: "date",
          numeric: false,
          disablePadding: true,
          label: "Date",
        },
        {
          id: "name",
          numeric: true,
          disablePadding: false,
          label: "Category",
        },
        {
          id: "amount",
          numeric: true,
          disablePadding: false,
          label: "Amount ₹",
        },
        {
          id: "remark",
          numeric: true,
          disablePadding: false,
          label: "Remarks",
        },
        {
          id: "actions",
          numeric: true,
          disablePadding: false,
          label: "Actions",
        },
      ];

  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{ backgroundColor: currentColor.tableHeadBackgroundColor }}
        >
          <Checkbox
            sx={{
              color: "white",
              backgroundColor: currentColor.tableHeadBackgroundColor,
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, idx) => (
          <TableCell
            key={idx}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "white",

              backgroundColor: currentColor.tableHeadBackgroundColor,
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({
  numSelected,
  deleteItem,
  editItem,
  selectedData,
  isEdit,
  saveEditData,
  editedInput,
  currentColor,
  isImageData,
  setDeleteAlert,
  setSaveAlert,
  errorMessage,
  setIsLoading,
  setErrorMessage,
  selected,
  jsonResult,
  setDeleteToggle,
  editedItem,
  manually,
}) {
  const deleteAlert = () => {
    if (selected <= 0 || numSelected <= 0) {
      setErrorMessage("Please select any items first");
      return;
    }

    const selectedItems = jsonResult.map((item) => selected.includes(item.id));

    if (
      selectedItems.some(
        (item) => item.category === "" || item.price === "" || item.date === ""
      )
    ) {
      setErrorMessage("Please fill out the Mandatory Fields");
      setIsLoading(false);
      return;
    } else {
      setErrorMessage("");
    }
    setDeleteToggle(true);
    setDeleteAlert(true);
  };

  const saveAlert = () => {
    if (selected <= 0 || numSelected <= 0) {
      setErrorMessage("Please select items first");
      return;
    }
    // const selectedItems = jsonResult.map((item) => selected.includes(item.id));
    const selectedItems = jsonResult.filter((item) =>
      selected.includes(item.id)
    );
    if (
      selectedItems.some(
        (item) => item.category === "" || item.price === "" || item.date === ""
      )
    ) {
      setErrorMessage("Please fill out the Mandatory Fields");
      return;
    } else {
      setErrorMessage("");
    }
    setSaveAlert(true);
  };

  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
        placeItems: "center",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ color: currentColor.textColor }}
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box>
          <Typography sx={{ color: currentColor.textColor }} variant="h6">
            {isImageData ? <>Review Expense</> : <>Expense</>}
          </Typography>
        </Box>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "red",
            textDecoration: "underline",
            textOverflow: "ellipsis",
            fontSize: "14px",
          }}
        >
          {manually}
        </Typography>

        <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
      </div>
      <Box>
        {numSelected > 0 && !isImageData ? (
          <Box>
            {isEdit && (
              <Tooltip title="Edit">
                <IconButton onClick={(event) => editItem(event)}>
                  <EditIcon sx={{ color: currentColor.buttonColor }} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton onClick={() => deleteAlert()}>
                <DeleteIcon sx={{ color: currentColor.buttonColor }} />
              </IconButton>
            </Tooltip>
            {!isEdit && (
              <Tooltip title="Save">
                <IconButton onClick={(e) => saveEditData(e, selectedData)}>
                  <SaveIcon sx={{ color: currentColor.buttonColor }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ) : (
          <></>
          // !isImageData && (
          //   <Tooltip title="Filter list">
          //     <IconButton>
          //       <FilterListIcon sx={{ color: currentColor.textColor }} />
          //     </IconButton>
          //   </Tooltip>
          // )
        )}

        {isImageData && (
          <Box sx={{ mr: ".5em" }}>
            {/* <Tooltip title="Delete">
              <IconButton onClick={() =>deleteAlert()}>
                <DeleteIcon sx={{ color: currentColor.buttonColor }} />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Save">
              <IconButton onClick={() => saveAlert()}>
                <SaveIcon sx={{ color: currentColor.buttonColor }} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ list, setList }) {
  const {
    currentColor,
    jsonResult,
    setJsonResult,
    base64String,
    setBase64String,
    checked,
    setChecked,
    isImageData,
    setIsImageData,
    file,
    setFile,
    isLoading,
    setIsLoading,
    setInput,
    CollapsableIcon,
    isAdmin,
  } = React.useContext(DataContext);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [editId, setEditId] = React.useState(-1);
  const [selectedData, setSelectedData] = React.useState({});
  const [isEdit, setIsEdit] = React.useState(true);
  const [openViewer, setOpenViewer] = React.useState(false);
  const [viewerContent, setViewerContent] = React.useState(null);
  const [isPDF, setIsPDF] = React.useState(false);
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [saveAlert, setSaveAlert] = React.useState(false);
  // const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [pic, setPic] = React.useState(null);
  const [manually, setManually] = React.useState("");
  const [deleteToggle, setDeleteToggle] = React.useState(false);
  const [editedItem, setEditedItem] = React.useState();
  const [totalprice, setTotalprice] = React.useState(0);
  const [editedInput, setEditedInput] = React.useState({
    id: -1,
    name: "",
    price: "",
    date: dayjs(),
    category: "",
    billName: "",
    billFile: "",
  });

  const NotifyDelete = () => toast.success("Items Delete successfully  ....");

  React.useEffect(() => {
    if (jsonResult && jsonResult.length > 0) {
      // Set the message after 2000ms
      const timeoutId = setTimeout(() => {
        setManually(
          "Please Recheck your Bills details before saving bills...."
        );
      }, 1000);

      const clearTimeoutId = setTimeout(() => {
        setManually("");
      }, 2500);

      return () => {
        clearTimeout(timeoutId);
        clearTimeout(clearTimeoutId);
      };
    }
  }, [jsonResult]);

  const notifySuccess = () => toast.success("Saved successfully  ....");

  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split(";").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };

  //   alldeleteItem code

  const deleteItem = async () => {
    setIsLoading(true);
    const accessToken = checkAccessTokenInCookie();
    console.log("accessToken", accessToken);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products/${selected.join(",")}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.ok) {
      setList((prev) => prev.filter((item) => !selected.includes(item.id)));
      setSelected([]);
      setDeleteToggle(false);
      setDeleteAlert(false);
    } else {
      // Handle error, e.g., show an alert or log the error
      console.error("Error deleting items:", response.statusText);
    }
    NotifyDelete();
    setIsLoading(false);
  };

  const editItem = (event) => {
    const items = list.filter((prev) => prev.id === selected[0]);
    setIsLoading(true);
    setEditId(selected[0]);

    const item = items[0];
    setEditedInput({
      id: item.id,
      name: item.itemName,
      price: item.price,
      category: item.category,
      date: dayjs(item.date),
      billfile: "",
      billName: item.billName,
    });

    setEditedItem(item);
    setIsEdit(false);
    setIsLoading(false);
  };

  const saveEditData = async (e, item) => {
    e.preventDefault();
    setIsLoading(true);

    const accessToken = checkAccessTokenInCookie();

    const formData = new FormData();
    formData.append("name", editedInput.name);
    formData.append("price", editedInput.price);
    formData.append("category", editedInput.category);

    const date =
      editedInput.date.toString()?.startsWith("20") ||
      editedInput.date.toString()?.startsWith("19")
        ? editedInput.date
        : editedInput.date.toISOString();

    formData.append("date", date);

    console.log("editedInput", editedInput);

    // Append file if it exists and has changed
    if (file) {
      formData.append("billfile2", file);
    }

    let formDataLength = 0;

    // Iterate over FormData entries and increment the counter
    for (let pair of formData.entries()) {
      formDataLength++;
    }

    if (formDataLength === 0) {
      setIsLoading(false);
      setEditId(-1);
      setIsEdit(true);
      setSelected([]);
      return;
    }

    console.log("formData", formData);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/products/${item.id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData === "Updated Successfully") {
          setList((prevList) => {
            const updatedList = prevList.map((fruit) =>
              fruit.id === item.id
                ? {
                    ...fruit,
                    itemName: editedInput.name || item.itemName,
                    price: editedInput.price || item.price,
                    category: editedInput.category || item.category,
                    date: date,
                    billfile: base64String,
                    billName: editedInput?.billname,
                  }
                : fruit
            );

            updatedList.sort((a, b) => new Date(b.date) - new Date(a.date));

            return updatedList;
          });
          setEditId(-1);
          setIsEdit(true);
          setSelected([]);
          setBase64String(null);
          console.log("firstfvfvff", list);
        } else {
          console.error("Error updating item:", responseData);
        }
      } else {
        console.error("Error updating item:", response.statusText);
      }
      if (response.status === 200) {
        notifySuccess();
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }

    setIsLoading(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = isImageData
        ? jsonResult.map((n) => n.id)
        : list.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, row) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelectedData(row);
    setSelected(newSelected);
  };

  const changeHandler = (e, field) => {
    if (field === "category") {
      setEditedInput((prev) => ({
        ...prev,
        category: e.target.value,
      }));
    } else if (field === "name") {
      setEditedInput((prev) => ({
        ...prev,
        name: e.target.value,
      }));
    } else if (field === "price") {
      setEditedInput((prev) => ({
        ...prev,
        price: e.target.value,
      }));
    } else if (field === "bill") {
      const uploadedFile = e.target.files[0];
      setFile(uploadedFile); // Assuming you are using this state elsewhere
      if (uploadedFile) {
        console.log("File selected: uploadedfill ", uploadedFile);
        // Read the file as a data URL
        const reader = new FileReader();
        reader.onload = function (event) {
          // Convert the data URL to base64
          const base64String = event.target.result.split(",")[1];
          console.log("base 64 change the bill file", base64String);
          setEditedInput((prev) => ({
            ...prev,
            billfile: base64String,
            billName: uploadedFile.name,
          }));
          console.log("Updated editedInput:", editedInput);
          setBase64String(base64String); // Assuming you are using this state elsewhere
        };
        reader.readAsDataURL(uploadedFile);
      }
    }
  };

  const AddHandler = async (e, id) => {
    e.preventDefault();
    // setLoading(true);
    // if (input.category === "") {
    //   setIsError({
    //     ...isError,
    //     category: true,
    //   });
    //   setLoading(false);
    //   return;
    // }
    // if (input.price === "") {
    //   setIsError({
    //     ...isError,
    //     price: true,
    //   });
    //   setLoading(false);
    //   return;
    // }
    console.log(jsonResult);

    const [oneData] = jsonResult.filter((item) => item.id === id);
    oneData.date = dayjs(oneData.date);
    const formData = new FormData();

    formData.append("billfile", file);
    formData.append("billName", file.name || "N/A");
    formData.append("name", oneData.name);
    formData.append("price", oneData.price);
    formData.append("category", oneData.category);
    formData.append("date", oneData.date.toISOString());

    console.log("One Data", oneData);

    console.log("The FortData-->", formData);

    const accessToken = checkAccessTokenInCookie();

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/products`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    const itemId = await res.json();
    console.log(jsonResult, "kjkkkkkk");
    if (res) {
      setList([
        ...list,
        {
          ...oneData,
          date: oneData.date.toISOString(),
          itemName: jsonResult[0]?.name,
          billfile: base64String,
          billName: file.name,
          id: itemId,
        },
      ]);
      console.log("first", "render list", list);
      setJsonResult((prev) => prev.filter((item) => item.id !== id));
    }

    notifySuccess();
    setChecked(false);
    setBase64String(null);
    setIsImageData(false);
    setInput({
      itemName: "",
      price: "",
      category: "",
      date: dayjs(Date.now()),
      billfile: "",
      billName: "",
    });
  };

  const addSelectedItems = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const formDataArray = [];
      const selectedItems = jsonResult.filter((item) =>
        selected.includes(item.id)
      );
      console.log("selectedItems +++--->", selected);

      for (const item of selectedItems) {
        const formData = new FormData();

        formData.append("billfile", file);
        formData.append("billName", file.name || "N/A");
        formData.append("name", item.name);
        formData.append("price", item.price);
        formData.append("category", item.category);
        formData.append("date", item.date);

        formDataArray.push(formData);
      }

      console.log(formDataArray, "jhjjj");
      const accessToken = checkAccessTokenInCookie();

      const responses = await Promise.all(
        formDataArray.map(async (formData) => {
          try {
            const res = await fetch(
              `${process.env.REACT_APP_API_URL}/api/products`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
              }
            );

            if (!res.ok) {
              throw new Error(`Request failed with status: ${res.status}`);
            }
            return res.json();
          } catch (error) {
            console.error("Error during fetch:", error);
            return { error: true };
          }
        })
      );

      console.log("responses abcd", responses);

      const newItems = responses.map((res, index) => ({
        ...jsonResult[index],
        itemName: jsonResult[index]?.name,
        billfile: base64String,
        billName: file.name,
        date: dayjs(jsonResult[index].date).toISOString(),
        id: res,
      }));

      console.log(newItems, "newItems");

      setList([...list, ...newItems]);

      // setJsonResult((prev) =>
      //   prev.filter((item) =>
      //     selected.includes(item.id) ? { ...item, selected: true } : item
      //   )
      // );
      setJsonResult((prev) =>
        prev.filter(
          (item) =>
            selected.includes(item.id)
              ? false // Remove the item from the array if its ID is in the selected array
              : true // Keep the item if its ID is not in the selected array
        )
      );
      setIsImageData(false);
      setSelected([]);
      setSaveAlert(false);
      notifySuccess();
      setChecked(false);
      setBase64String(null);
      setInput({
        itemName: "",
        price: "",
        category: "",
        date: dayjs(Date.now()),
        billfile: "",
        billName: "",
      });
    } finally {
      setIsLoading(false);
    }
  };

  console.log("first", list);

  const ImageDataHandler = (e, field, id) => {
    // const data = jsonResult.map((item) =>
    //   item.id === id ? { ...item, [field]: e.target.value } : item
    // );
    // setJsonResult(data);

    setJsonResult((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [field]: e.target.value } : item
      )
    );
  };

  const deleteImageItem = (e, id) => {
    setJsonResult((prev) => prev.filter((item) => item.id !== id));
    setIsImageData(false);
    NotifyDelete();
    setChecked(false);
    setBase64String(null);
    setInput({
      itemName: "",
      price: "",
      category: "",
      date: dayjs(Date.now()),
      billfile: "",
      billName: "",
    });
  };

  const handleViewerOpen = (fileData) => {
    const letter = fileData.charAt(0);
    if (letter == "J") {
      setIsPDF(true);
    } else {
      setIsPDF(false);
    }
    setViewerContent(fileData);
    setOpenViewer(true);
  };

  const handleViewerClose = () => {
    setOpenViewer(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClose = () => {
    setDeleteAlert(false);
  };

  const handleCloSaveAlert = () => {
    setSaveAlert(false);
  };

  const deleteMultiOption = () => {
    setJsonResult((prev) => prev.filter((item) => !selected.includes(item.id)));
    setDeleteAlert(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const sortedList = [...list].sort((a, b) => {
    return order === "asc" ? a.id - b.id : b.id - a.id;
  });

  const visibleRows = sortedList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  React.useEffect(() => {
    const total = list.reduce((sum, item) => sum + parseFloat(item.price), 0);
    setTotalprice(total);
  }, [jsonResult, selectedData, list]);
  return (
    <Box>
      <Paper
        sx={{
          backgroundColor: currentColor.formBackgroundColor,
          //  minHeight:{ md:"20em",lg:"30em"},
        
          width: { xs:"92vw",sm: "90vw", md: isAdmin &&  !CollapsableIcon ?  "51vw" : "63.5vw", lg: isAdmin &&  !CollapsableIcon ? "51vw" : "63.5", xl:"56vw" },
        }}
      >
        <EnhancedTableToolbar
          numSelected={selected.length}
          deleteItem={deleteItem}
          editItem={editItem}
          selectedData={selectedData}
          isEdit={isEdit}
          saveEditData={saveEditData}
          editedInput={editedInput}
          currentColor={currentColor}
          isImageData={isImageData}
          setDeleteAlert={setDeleteAlert}
          setSaveAlert={setSaveAlert}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setIsLoading={setIsLoading}
          selected={selected}
          jsonResult={jsonResult}
          setDeleteToggle={setDeleteToggle}
          setEditedItem={setEditedItem}
          editedItem={editedItem}
          manually={manually}
          totalprice={totalprice}
          order={order}
        />

        <TableContainer
          sx={{
            maxHeight: { xs: "19em", sm: "20em", md: "20.25em", lg: "22.8em" },
            minHeight: { xs: "19em", sm: "20em", md: "20.25em", lg: "22.8em" },
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                alignItems: "center",
              }}
            >
              <Loading />
            </Box>
          ) : (
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              stickyHeader
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={isImageData ? jsonResult?.length : list.length}
                currentColor={currentColor}
                isImageData={isImageData}
                selectedData={selectedData}
              />
              <TableBody>
                {isImageData &&
                  jsonResult.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    // if (isItemSelected === true) {
                    //   console.log("this is callllled.....");
                    //   setSelectedData(row);
                    // }

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={row.id}>
                        {!isImageData ? (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{
                              cursor: "pointer",
                              color: currentColor.textColor,
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>

                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="normal"
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.date.substring(0, 10)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.category}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.price}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.name}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor, m: 0, p: 0 }}
                            >
                              {base64String === "N/A" ||
                              base64String === "" ||
                              base64String === "N/A" ||
                              base64String === "" ? (
                                <p>N/A</p>
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewerOpen(base64String);
                                  }}
                                  sx={{
                                    color: currentColor.iconcolor,
                                    m: 0,
                                    p: 0,
                                  }}
                                >
                                  {base64String?.startsWith("J") ? (
                                    <PictureAsPdfIcon />
                                  ) : (
                                    (base64String?.startsWith("/") ||
                                      base64String?.startsWith("i")) && (
                                      <ImageIcon />
                                    )
                                  )}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            //sx={{ cursor: "pointer",  }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                onChange={() => setErrorMessage("")}
                                onClick={(event) =>
                                  handleClick(event, row.id, row)
                                }
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                pb: "0px",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  slotProps={{
                                    textField: { variant: "standard" },
                                  }}
                                  variant="inline"
                                  value={dayjs(row.date)}
                                  onChange={(newValue) =>
                                    setJsonResult((prev) =>
                                      prev.map((item) =>
                                        item.id === row.id
                                          ? {
                                              ...item,
                                              date: newValue.toISOString(),
                                            }
                                          : item
                                      )
                                    )
                                  }
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                textAlign: "center",
                                pb: "0px",
                              }}
                            >
                              <FormControl
                                fullWidth
                                sx={{
                                  borderBottom:
                                    row.category === ""
                                      ? "2px solid red"
                                      : `1px solid ${currentColor.formBackGroundColor}`,
                                }}
                              >
                                <Select
                                  value={row.category}
                                  // label="Category"
                                  variant="standard"
                                  onChange={(e) =>
                                    ImageDataHandler(e, "category", row.id)
                                  }
                                  disableUnderline={
                                    !categories.some(
                                      (cat) => cat.item === row.category
                                    )
                                  }
                                  sx={{
                                    borderBottom: !categories.some(
                                      (cat) => cat.item === row.category
                                    )
                                      ? "2px solid red"
                                      : `1px solid ${currentColor.formBackGroundColor}`,
                                  }}
                                >
                                  {categories.map((category, idx) => (
                                    <MenuItem key={idx} value={category.item}>
                                      {category.item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                textAlign: "center",
                                pb: "0px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                // label="Amount"
                                variant="standard"
                                value={row.price}
                                onChange={(e) =>
                                  ImageDataHandler(e, "price", row.id)
                                }
                                InputProps={{
                                  disableUnderline:
                                    row.price === "" ? true : false,
                                  inputProps: {
                                    style: { textAlign: "center" },
                                    pattern: "[0-9]*", // Allow only numeric input
                                  },
                                }}
                                onInput={(e) => {
                                  // Remove non-numeric characters
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  // Call your ImageDataHandler function here
                                  //ImageDataHandler(e, "price", row.id);
                                }}
                                sx={{
                                  borderBottom:
                                    row.price === ""
                                      ? "2px solid red"
                                      : `1px solid ${currentColor.formBackGroundColor}`,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                textAlign: "center",
                                pb: "0px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                // label="Remarks"
                                variant="standard"
                                value={row.name}
                                onChange={(e) =>
                                  ImageDataHandler(e, "name", row.id)
                                }
                                inputProps={{ style: { textAlign: "center" } }}
                                InputProps={{
                                  disableUnderline:
                                    row.name === "" ? true : false,
                                }}
                                sx={{
                                  borderBottom:
                                    row.name === ""
                                      ? "2px solid red"
                                      : `1px solid ${currentColor.formBackGroundColor}`,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                textAlign: "center",
                                pb: "0px",
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={(e) => deleteImageItem(e, row.id)}
                                >
                                  <DeleteIcon
                                    sx={{ color: currentColor.buttonColor }}
                                  />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Save">
                                <IconButton
                                  onClick={(e) => {
                                    AddHandler(e, row.id);
                                  }}
                                >
                                  <SaveIcon
                                    sx={{ color: currentColor.buttonColor }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}

                {!isImageData &&
                  visibleRows?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);

                    // if (isItemSelected === true) {
                    //   setSelectedData(row);
                    // }

                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <React.Fragment key={row.id}>
                        {editId !== row.id ? (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.id, row)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{
                              cursor: "pointer",
                              color: currentColor.textColor,
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="normal"
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.date.substring(0, 10)}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.category}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.price}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor }}
                            >
                              {row.itemName}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{ color: currentColor.textColor, m: 0, p: 0 }}
                            >
                              {row.billName === "N/A" ||
                              row.billName === "" ||
                              row.billfile === "N/A" ||
                              row.billfile === "" ? (
                                <p>N/A</p>
                              ) : (
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleViewerOpen(
                                      row.billfile || row.billFile
                                    );
                                  }}
                                  sx={{
                                    color: currentColor.iconcolor,
                                    m: 0,
                                    p: 0,
                                  }}
                                >
                                  {/* {JSON.stringify("krishna" , row.billfile)} */}
                                  {row.billfile?.startsWith("J") ? (
                                    <PictureAsPdfIcon />
                                  ) : (
                                    (row.billfile?.startsWith("/") ||
                                      row.billfile?.startsWith("i")) && (
                                      <ImageIcon />
                                    )
                                  )}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key={row.id}>
                            {/* <Grid container direction="row" gap={2}> */}
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                pb: "0px",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  // label="Date"

                                  slotProps={{
                                    textField: { variant: "standard" },
                                  }}
                                  variant="inline"
                                  value={editedInput.date}
                                  onChange={(newValue) =>
                                    setEditedInput({
                                      ...editedInput,
                                      date: newValue.toISOString(),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "95px",
                                textAlign: "center",
                                pb: "0px",
                              }}
                            >
                              <FormControl fullWidth>
                                <Select
                                  value={editedInput.category}
                                  // label="Category"
                                  variant="standard"
                                  onChange={(e) => changeHandler(e, "category")}
                                >
                                  {categories.map((category, idx) => (
                                    <MenuItem key={idx} value={category.item}>
                                      {category.item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                pb: "0px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                // label="Amount"
                                variant="standard"
                                value={editedInput.price}
                                onChange={(e) => changeHandler(e, "price")}
                                inputProps={{ style: { textAlign: "center" } }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                width: "auto",
                                pb: "0px",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                // label="Remarks"
                                variant="standard"
                                value={editedInput.name}
                                onChange={(e) => changeHandler(e, "name")}
                                inputProps={{ style: { textAlign: "center" } }}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: currentColor.textColor,
                                m: 0,
                                p: 0.5,
                                display: "flex",
                                mt: 2,
                                justifyContent: "center",
                                gap: 1,
                              }}
                            >
                              {/* <div>
                                <label htmlFor="fileInput">
                                  <CloudUploadIcon
                                    style={{
                                      color: currentColor.textColor,
                                      margin: "0px",
                                      padding: "0px",
                                      border: "none",
                                      outline: "none",
                                      cursor: "pointer",
                                      "&hover": { color: "red" },
                                      backgroundColor: "none",
                                    }}
                                  />
                                </label>
                                <input
                                  type="file"
                                  id="fileInput"
                                  accept=".pdf, .doc, .docx,jpg, jpeg,png"
                                  style={{ display: "none" }}
                                  onChange={(e) => changeHandler(e, "bill")}
                                />
                              </div> */}
                              <Grid>
                                <Input
                                  type="file"
                                  id="fileInput2"
                                  accept=".pdf, .doc, .docx,jpg, jpeg,png" // Define the allowed file types
                                  onChange={(e) => changeHandler(e, "bill")}
                                  style={{
                                    display: "none",
                                    cursor: "pointer",
                                  }}
                                />
                                <InputLabel
                                  htmlFor="fileInput2"
                                  className="upload-button"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <CloudUploadIcon />
                                </InputLabel>
                                {/* <Typography
                                  style={{ textAlign: "left", height: "5px" }}
                                >
                                  {/* {input.billName ? input.billName : " "} */}
                                {/* </Typography>  */}
                              </Grid>

                              <>
                                {row.billName === "N/A" ||
                                row.billName === "" ||
                                row.billfile === "N/A" ||
                                row.billfile === "" ? (
                                  <p>N/A</p>
                                ) : (
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleViewerOpen(
                                        row.billfile || row.billFile
                                      );
                                    }}
                                    style={{
                                      color: currentColor.iconcolor,
                                      margin: 0,
                                      padding: "0px",
                                      border: "none",
                                      outline: "none",
                                    }}
                                  >
                                    {row.billfile?.startsWith("J") ? (
                                      <PictureAsPdfIcon />
                                    ) : (
                                      (row.billfile?.startsWith("/") ||
                                        row.billfile?.startsWith("i")) && (
                                        <ImageIcon />
                                      )
                                    )}
                                  </button>
                                )}
                              </>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Dialog
          open={openViewer}
          onClose={handleViewerClose}
          maxWidth="md"
          sx={{
            padding: "none",
            textAlign: "center",
            height: "100%",
          }}
          fullWidth={isPDF}
        >
          <DialogTitle
            sx={{ color: currentColor.textColor, p: "0px" }}
          ></DialogTitle>
          <DialogContent
            sx={{
              padding: "0px",
            }}
          >
            {isPDF ? (
              <PdfViewer base64String={viewerContent} />
            ) : (
              <ImageViewer base64String={viewerContent} />
            )}
          </DialogContent>
        </Dialog>
        {/* this the Delete Alert */}
        <Dialog
          // fullWidth={fullWidth}

          maxWidth="o"
          sx={{
            padding: "none",
            textAlign: "center",
            height: "100%",
            alignItems: "center",
          }}
          open={deleteAlert}
          onClose={handleClose}
        >
          {/* <DialogTitle textAlign="center"> </DialogTitle> */}
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "18.5rem", sm: "25rem", md: "25rem", lg: "30rem" },
              paddingBottom: "20px",
            }}
          >
            <Box sx={{ fontSize: "5rem", color: "red" }}>
              <ErrorOutlineIcon />
            </Box>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              Are You really Want to Delete?
            </Typography>
          </Box>

          <DialogActions sx={{ backgroundColor: "#FFFFFF" }}>
            <Box
              pb={2}
              display="flex"
              gap={2}
              sx={{
                marginRight: {
                  xs: ".1rem",
                  sm: "1.5rem",
                  md: "2rem",
                  lg: "3.6rem",
                },
              }}
            >
              <Button
                variant="gradient"
                color="info"
                size="medium"
                onClick={true ? deleteItem : deleteMultiOption}
              >
                Confirm
              </Button>

              <Button
                variant="gradient"
                color="light"
                size="medium"
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        <Dialog
          // fullWidth={fullWidth}
          maxWidth="md"
          sx={{
            padding: "none",
            textAlign: "center",
            height: "100%",
          }}
          open={saveAlert}
          onClose={handleCloSaveAlert}
        >
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "18.5rem", sm: "25rem", md: "25rem", lg: "30rem" },

              paddingBottom: "20px",
            }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <Box>
                <Box sx={{ fontSize: "5rem", color: "red" }}>
                  <ErrorOutlineIcon />
                </Box>

                <Typography
                  variant="h5"
                  sx={{ fontWeight: { xs: "900", sm: "600" } }}
                >
                  Do you really want to save all selected Expenses ?
                </Typography>

                <DialogActions sx={{ backgroundColor: "#FFFFFF" }}>
                  <Box mr={5} pb={2} display="flex" gap={2}>
                    <Button
                      variant="gradient"
                      color="info"
                      size="medium"
                      onClick={addSelectedItems}
                    >
                      Confirm
                    </Button>

                    <Button
                      variant="gradient"
                      color="light"
                      size="medium"
                      onClick={handleCloSaveAlert}
                    >
                      Close
                    </Button>
                  </Box>
                </DialogActions>
              </Box>
            )}
          </Box>
        </Dialog>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row", lg: "row" },
            paddingX: "1em",
            color: currentColor.backgroundColor,
            mt: { xs: ".5em", lg: "0" },
            alignItems: "center",
          }}
        >
          {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          /> */}
          <Typography>Total Expense : {totalprice}</Typography>

          <TablePagination
            rowsPerPageOptions={[5, 10, 30]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
      {/* <ToastContainer position="bottom-right"/> */}
    </Box>
  );
}
