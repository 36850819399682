import React, { useContext, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  ToggleButton,
  Typography,
} from "@mui/material";

import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { categories } from "../utils/constant";
import { DataContext } from "../utils/ContextProider";
import Loading from "./Loading";
import OpenAI from "openai";
import Tesseract from "tesseract.js";
import Draggable from "react-draggable";
import PdfViewer from "./PdfViewer";
import ImageViewer from "./ImageViewer";

const AddExpense = ({ list, setList }) => {
  const {
    currentColor,
    jsonResult,
    setJsonResult,
    base64String,
    setBase64String,
    checked,
    setChecked,
    isImageData,
    setIsImageData,
    file,
    setFile,
    input,
    setInput,
  } = useContext(DataContext);

  const [isError, setIsError] = useState({
    category: false,
    price: false,
    date: false,
    name: false,
    billfile: false,
  });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [pic, setPic] = useState(null);
  const [extractedText, setExtractedText] = useState("");
  const [openViewer, setOpenViewer] = React.useState(false);
  const [isPDF, setIsPDF] = React.useState(false);

  const notifySuccess = () => toast.success("Bill successfully added ....");

  const notifyError = () =>
    toast.error("Please fill in all required fields .... ");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // get accesToken from Cookies
  // Function to check for the presence of the access token in the cookie
  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };

  const accessToken = checkAccessTokenInCookie();

  const changeHandler = (e, field) => {
    if (field === "name") {
      setInput({
        ...input,
        itemName: e.target.value,
      });
    }
    if (field === "price") {
      setInput({
        ...input,
        price: e.target.value,
      });
    }
    if (field === "category") {
      setInput({
        ...input,
        category: e.target.value,
      });
    }
  };

  const AddHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      input.category === "" ||
      input.price === "" ||
      input.itemName === "" ||
      input.date === "" ||
      input.billfile === ""
    ) {
      setIsError({
        category: input.category === "",
        price: input.price === "",
        date: input.date === "",
        name: input.itemName === "",
        billfile: input.billfile === "",
      });

      setLoading(false);
      notifyError();
      return;
    }

    console.log("Input date--->", input.date);

    const formData = new FormData();

    formData.append("billfile", file);
    formData.append("billName", input.billName || "N/A");
    formData.append("name", input.itemName);
    formData.append("price", input.price);
    formData.append("category", input.category);
    formData.append("date", input.date.toISOString());

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/products`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    });

    const itemId = await res.json();

    if (res.status === 200) {
      notifySuccess();
    }
    if (res) {
      console.log("Response from aaaa", res);
      setList([
        ...list,
        {
          ...input,
          date: input.date.toISOString(),
          id: itemId,
        },
      ]);
      setPic(null);
      setBase64String(null);
      setLoading(false);
    }

    setInput({
      itemName: "",
      price: "",
      category: "",
      date: dayjs(Date.now()),
      billfile: "",
      billName: "",
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, field) => {
    e.preventDefault();
    const uploadedFile = e.dataTransfer.files[0];
    setFile(uploadedFile);
    if (field === "bill") {
      if (uploadedFile) {
        // Read the file as a data URL
        var reader = new FileReader();
        reader.onload = function (e) {
          // Convert the data URL to base64
          var base64String = e.target.result.split(",")[1];
          setInput({
            ...input,
            billfile: base64String,
            billName: uploadedFile.name,
          });
          setBase64String(base64String);
          // Display the base64 string
          const letter = base64String.charAt(0);
          if (letter == "J") {
            setIsPDF(true);
          } else {
            setIsPDF(false);
          }
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        console.error("No file selected");
      }
    }
  };

  // Function to handle file upload
  const handleFileUpload = (event, field) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    const file = event.target.files[0];
    if (file) {
      setPic(URL.createObjectURL(file));
    }

    if (field === "bill") {
      if (uploadedFile) {
        // Read the file as a data URL
        var reader = new FileReader();
        reader.onload = function (e) {
          // Convert the data URL to base64
          var base64String = e.target.result.split(",")[1];

          setInput({
            ...input,
            billfile: base64String,
            billName: uploadedFile.name,
          });
          setBase64String(base64String);
          // Display the base64 string
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        console.error("No file selected");
      }
    }
  };

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_KEY,
    dangerouslyAllowBrowser: true,
  });

  const handleExtractText = () => {
    setJsonResult(null); // Change to null
    setLoading(true);

    if (pic) {
      Tesseract.recognize(pic, "eng", {})
        .then(({ data: { text } }) => {
          setExtractedText(text);
          getResult(text);
        })
        .catch((error) => {
          console.error("Error during text recognition:", error);
        });
    }
  };

  const getResult = async (prompt) => {
    // console.log("this the prompt to be send to CHATGPT", prompt);
    const userMessage = {
      role: "user",
      content: `${prompt} this is my Image data which I get from the tesseract response from the image and from this Image data, I want to extract meaningful information in JSON format. 
      I only need the following data: 
      {
        "id" : "this is will be the unique auto-increment id start from 1"
        "category": "Choose from: Snacks, Food and Dining, Transportation, Entertainment, Healthcare, Education, Personal Care, Insurance, Investments, Miscellaneous, others"
        "price": "The price; you can find it by properties like currency symbol in prefix, Price, Sale",
        "name": "The name of the product",
        "date": "The date when the product was purchased, the date must be in format always gave me date in this format, 
        M
        $D
        : 
        24
        $H
        : 
        11
        $L
        : 
        "en"
        $M
        : 
        0
        $W
        : 
        3
        $d
        : 
        
        $isDayjsObject
        : 
        true
        $m
        : 
        16
        $ms
        : 
        374
        $s
        : 
        38
        $u
        : 
        undefined
        $x
        : 
        {}
        $y
        : 
        2024"
      }
      Convert this into a meaningful JSON format. I don't need any explanation; only provide the JSON output. 
      Use the format: {"id" : "this is will be the unique auto-increment id start from 1", "category": "Chosen Category  and if it is not from above options make it other ", "price": "Price (give me only numerical value) ", "name": "Product Name", "date": "The date when the product was purchased, the date must be in format always gave me date in this format, 
      M
      $D
      : 
      
      $H
      : 
      
      $L
      : 
      "en"
      $M
      : 
      
      $W
      : 
      
      $d
      : 
       {}
      $isDayjsObject
      : 
      true
      $m
      : 
      
      $ms
      : 
      
      $s
      : 
      
      $u
      : 
      undefined
      $x
      : 
      {}
      $y
      : 
      "" }
      I don't want to see \`  \` \`json in the returned data; give me the output in a proper format without the use of backticks.
      I want to use this data as the map function so always return me data into an array
     `,
    };

    // setChat((prevChat) => [...prevChat, userMessage]);

    try {
      const chatCompletion = await openai.chat.completions.create({
        messages: [userMessage],
        model: "gpt-3.5-turbo-1106",
        max_tokens: 1000,

        // response_format: { type: "json_object" },
      });

      const assistantMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      const chatbotResponse = assistantMessage.content;

      console.log("this is the chatbotResponse", chatbotResponse);

      console.log("JSON.parse(chatbotResponse)", JSON.parse(chatbotResponse));

      console.log(typeof chatbotResponse);

      if (assistantMessage.content !== "") {
        setLoading(false);
        handleClickOpen();
        setIsImageData(true);
      }
      console.log("first", chatbotResponse.length);

      // if (chatbotResponse.date === " " || chatbotResponse.price === " " || chatbotResponse.category === " " || chatbotResponse.remarks === " " ) {

      //   setManuallymsg("Please fill in the details manually.");
      //   return;
      // }

      //  const chatbotResponse = chatCompletion.data.choices[0].message.content;

      setJsonResult(JSON.parse(chatbotResponse));
    } catch (error) {
      console.error("Error fetching chat completion:", error);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleViewerClose = () => {
    setOpenViewer(false);
  };
  useEffect(() => {
    if (pic && checked) {
      handleExtractText();
    }
  }, [file, pic]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: currentColor.formBackgroundColor,
          borderRadius: "3px",
          display: "flex",
          justifyContent: "center",
          alignItems: !checked || loading ? "center" : "",
          height: { xs: "32em", sm: "25em", md: "28em", lg: "30em", xl:"30em" },
          width: { xs: "100%", sm: "100%", md: "15em", lg: "17em", xl:"17em" },
          // border:"2px solid red"
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Stack
            sx={{
              p: "1em",
              color: currentColor.backgroundColor,
              backgroundColor: currentColor.formBackGroundColor,
              textAlign: "center",
              // border:"2px solid red",
              gap: {
                xs: 2,
                md: 1,
                lg: 1.5,
              },
              width: { xs: "80%", sm: "100%", md: "92%" , lg:"100%" , xl:"100%"},
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                placeItems: "center",
                // border:"2px solid red",
                alignItems: "center",
              }}
            >
              {!checked ? <h3>Add Expense</h3> : <h3>Add Expense via AI</h3>}
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
            {!checked ? (
              <Grid
                container
                direction={{
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "column",
                }}
                sx={{
                  gap: { xs: 1.2, md: 1, lg: 1.5 },
                  //  border:"2px solid red"
                }}
              >
                <Grid>
                  <FormControl required fullWidth>
                    <InputLabel id="" error={isError.category}>
                      Category
                    </InputLabel>
                    <Select
                      // labelId="demo-simple-select-label"
                      // id="demo-simple-select"
                      value={input.category}
                      error={isError.category}
                      label="Category"
                      onChange={(e) => changeHandler(e, "category")}
                      onClick={(e) => setIsError(false)}
                      sx={{
                        textAlign: "left",
                        width: {
                          lg: "100%",
                          md: "13.3em",
                          sm: "20em",
                          xs: "100%",
                        },
                      }}
                    >
                      {categories.map((category, idx) => (
                        <MenuItem key={idx} value={category.item} required>
                          {category.item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid>
                  <TextField
                    id="outlined-basic"
                    label="Amount"
                    error={isError.price}
                    variant="outlined"
                    value={input.price}
                    onChange={(e) => changeHandler(e, "price")}
                    onClick={(e) => setIsError(false)}
                    sx={{
                      width: {
                        lg: "100%",
                        md: "13.3em",
                        sm: "20em",
                        xs: "100%",
                      },
                    }}
                    type="number"
                    required
                  />
                </Grid>
                <Grid>
                  <TextField
                    id="outlined-basic"
                    label="Remarks"
                    variant="outlined"
                    error={isError.name}
                    value={input.itemName}
                    onChange={(e) => changeHandler(e, "name")}
                    onClick={(e) => setIsError(false)}
                    sx={{
                      width: {
                        lg: "100%",
                        md: "13.3em",
                        sm: "20em",
                        xs: "100%",
                      },
                    }}
                    type="text"
                    helperText=""
                    required
                  />
                </Grid>
                <Grid>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date"
                      value={input.date}
                      inputStyle={{
                        color: "red",
                      }}
                      onChange={(newValue) =>
                        setInput({ ...input, date: newValue })
                      }
                      onClick={(e) => setIsError(false)}
                      sx={{
                        width: {
                          lg: "100%",
                          md: "13.3em",
                          sm: "20em",
                          xs: "100%",
                        },
                      }}
                      required
                      disableFuture
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid>
                  <Input
                    type="file"
                    id="fileInput"
                    error={isError.billfile}
                    slotProps={{
                      input: {
                        accept: "image/*",
                      },
                    }}
                    onChange={(e) => handleFileUpload(e, "bill")}
                    style={{
                      display: "none",
                      cursor: "pointer",
                    }}
                    onClick={(e) => setIsError(false)}
                  />
                  <InputLabel
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e, "bill")}
                    htmlFor="fileInput"
                    className="upload-button"
                    sx={{
                      cursor: "pointer",
                      border: isError.billfile
                        ? "1.5px dashed red"
                        : "1.5px dashed gray",

                      borderRadius: "5px",
                      padding: "12px",
                      width: {
                        lg: "90%",
                        md: "11.7em",
                        sm: "39em",
                        // xs: "92%",
                      },
                    }}
                    required
                  >
                    Upload Bill
                  </InputLabel>

                  <Typography
                    sx={{
                      textAlign: "left",
                      maxHeight: "4px",
                      color: "#555555",
                      width: "100%",
                      textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    {input.billName ? input.billName : " "}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid sx={{ textAlign: "center" }}>
                <Input
                  type="file"
                  id="fileInput"
                  accept=".pdf, .doc, .docx,jpg, jpeg,png" // Define the allowed file types
                  onChange={(e) => handleFileUpload(e, "bill")}
                  style={{
                    display: "none",
                    cursor: "pointer",
                    backgroundColor: "black",
                  }}
                />
                <InputLabel
                  onDragOver={(e) => handleDragOver(e)}
                  onDrop={(e) => handleDrop(e, "bill")}
                  htmlFor="fileInput"
                  className="upload-button"
                  style={{
                    cursor: "pointer",
                    color: "gray",
                    border: "2px dashed gray",
                    borderRadius: "5px",
                    padding: "12px",
                    height: isImageData ? ".5em" : "7em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Upload Bill Image
                </InputLabel>
                <Button
                  sx={{
                    width: { xs: "17em", sm: "35em", md: "16em", lg: "17em" },
                  }}
                  onClick={(e) => setOpenViewer(true)}
                >
                  {isPDF ? (
                    <PdfViewer base64String={base64String} />
                  ) : (
                    <ImageViewer base64String={base64String} />
                  )}
                </Button>
                {/* <Typography style={{ textAlign: "center", height: "5px" }}>
                  {input.billName ? input.billName : " "}
                </Typography> */}
              </Grid>
            )}
            {!checked && (
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "8px",
                    marginBottom: "0px",
                    paddingInline: {
                      xs: "35%",
                      sm: "25%",
                      md: "25%",
                      lg: "25%",
                    },
                    backgroundColor: currentColor.buttonColor,
                    "&hover": { backgroundColor: "gray" },
                  }}
                  onClick={(e) => AddHandler(e)}
                >
                  Add
                </Button>
              </Stack>
            )}
          </Stack>
        )}
      </Box>

      <Dialog
        open={openViewer}
        onClose={handleViewerClose}
        maxWidth="md"
        sx={{
          padding: "none",
          textAlign: "center",
          height: "100%",
        }}
        fullWidth={isPDF}
      >
        <DialogTitle
          sx={{ color: currentColor.textColor, p: "0px" }}
        ></DialogTitle>
        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
          {isPDF ? (
            <PdfViewer base64String={base64String} />
          ) : (
            <ImageViewer base64String={base64String} />
          )}
        </DialogContent>
      </Dialog>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default AddExpense;
