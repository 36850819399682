import React, { useState, useEffect, useContext } from "react";
import { DataContext } from "../utils/ContextProider";
import { Box } from "@mui/material";

const ImageViewer = ({ base64String }) => {
  const { currentColor } = useContext(DataContext);
  // const binaryData = atob(base64String);
  // const arrayBuffer = new ArrayBuffer(binaryData.length);
  // const view = new Uint8Array(arrayBuffer);
  // for (let i = 0; i < binaryData.length; i++) {
  //   view[i] = binaryData.charCodeAt(i);
  // }

  // // Create Blob from binary data
  // const blob = new Blob([arrayBuffer], { type: "image" });

  // // Create data URL from Blob
  // const url = URL.createObjectURL(blob);
  // console.log("Baseic", base64String.startsWith("data:application/pdf"))
  return (
    <Box
      sx={{ color: currentColor.backgroundColor, p: "0px", margin: "0px" ,
       width:{xs:"90%" , sm:"70%" , lg:"100%"}
       
      }}
    >
      {base64String != null && (
        <img
          src={`data:image;base64,${base64String}`}
          style={{
            border: "2px solid gray",
            width:"100%"
            
          }}
          alt="Uploaded"
        />
      )}
    </Box>
  );
};

export default ImageViewer;
