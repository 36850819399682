import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { DataContext } from "../utils/ContextProider";

const AddAdmin = () => {
  const { currentColor, adminData, setAdminData } = useContext(DataContext);
  const [input, setInput] = useState({ name: "", email: "" });
  const [isError, setIsError] = useState({ name: false, email: false });

  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };
  const accessToken = checkAccessTokenInCookie();

  const changeHandler = (e, field) => {
    if (field === "name") {
      setInput({
        ...input,
        name: e.target.value,
      });
    }
    if (field === "email") {
      setInput({
        ...input,
        email: e.target.value,
      });
    }
  };

  const AddHandler = async (e) => {
    e.preventDefault();
    if (input.name === "") {
      setIsError({
        ...isError,
        name: true,
      });
      return;
    }
    if (input.email === "") {
      setIsError({
        ...isError,
        email: true,
      });
      return;
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/addAdmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(input),
    }).then((res) => res.json());

    if (res === true) {
      setAdminData([...adminData, input]);
    }
    setInput({
      name: "",
      email: "",
    });
  };

  return (
    <Box
      minHeight={320}
       sx={{
        backgroundColor: currentColor.formBackgroundColor,
        borderRadius: "5px",
        minWidth:{sm :"8em", md:"15em" , lg:"20em"}
      }}
    >
      <Stack
        spacing={4}
        sx={{
          p: "1em",
          color: currentColor.backgroundColor,
          backgroundColor: currentColor.formBackGroundColor,
          textAlign: "center",
          minHeight: 200,
        }}
      >
        <h2 variant="h3">Add New Admin</h2>
        <Grid
          container
          direction={{ xs: "row", sm: "column", md: "column" }}
          gap={2}
          width={{ xs: "21em", sm: "auto" }}
          justifyContent={"center"}
        
        >
          <Grid>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={input.name}
              onChange={(e) => changeHandler(e, "name")}
              onClick={(e) => setIsError({ ...isError, name: false })}
              sx={{
                width: "100%",
                border: isError.name
                  ? "1px solid red"
                  : `1px solid ${currentColor.formBackGroundColor}`,
              }}
              type="text"
              required
            />
          </Grid>
          <Grid>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={input.email}
              onChange={(e) => changeHandler(e, "email")}
              onClick={(e) => setIsError({ ...isError, email: false })}
              sx={{
                width: "100%",
                border: isError.email
                  ? "1px solid red"
                  : `1px solid ${currentColor.formBackGroundColor}`,
              }}
              type="text"
              helperText=""
              required
            />
          </Grid>
        </Grid>

        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "120px",
              backgroundColor: currentColor.buttonColor,
              "&hover": { backgroundColor: "gray" },
            }}
            onClick={(e) => AddHandler(e)}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AddAdmin;
