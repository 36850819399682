import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { DataContext } from "../utils/ContextProider";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./Loading";
import axios from "axios";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

const Signup = () => {
  const {
    accessToken,
    setAccessToken,
    isAuthenticated,
    setIsAuthenticated,
    currentColor,
    signupLoader,
    setSignupLoader,
  } = useContext(DataContext);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!data.email.endsWith("dyotis.com")) {
    //   setError("Email must end with dyotis.com");
    //   return;
    // }
    setIsLoading(true);

    try {
      // console.log("Data-->", data);

      const newData = { ...data, email: data.email + "@dyotis.com" };

      // console.log(" The new data ==>", newData);

      const url = `${process.env.REACT_APP_API_URL}/api/users`;

      const { data: res } = await axios.post(url, newData);

      setMsg(res.message);

      // console.log("This is response", res.message);
      // console.log("this is the message", msg);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "@") {
      event.preventDefault();
    }
  };

  // const submit = async (e) => {
  //   setSignupLoader(true);
  //   e.preventDefault();
  //   if (!loginDetails.email) {
  //     setError("Please enter your email.");
  //     setSignupLoader(false);
  //     setWarning(true);
  //     return; // Exit the function
  //   }
  //   // Check if the entered email follows a basic format
  //   const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   if (!emailFormatRegex.test(loginDetails.email)) {
  //     setError("Please enter a valid email address.");
  //     setSignupLoader(false);
  //     return; // Exit the function
  //   }

  //   if (!loginDetails.password) {
  //     setSignupLoader(false);
  //     setError("Please enter your password.");

  //     return; // Exit the function
  //   }
  //   await fetch("https://ankitkumar1212.us.auth0.com/dbconnections/signup", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(signUpData),
  //   })
  //     .then((response) => response.json())
  //     .then((signUpResponse) => {
  //       // Extract the access token from the sign-up response
  //       if (signUpResponse._id) {
  //         getAccessToken();
  //         // user is Authenticated now send Api hit to the backend and get the data
  //         // setIsAuthenticated(true);
  //       }
  //     })
  //     .catch((error) => {
  //       setSignupLoader(false);
  //       console.error("Error:", error);
  //       return null;
  //     });
  // };

  // const getAccessToken = async () => {
  //   const data = {
  //     grant_type: "password",
  //     username: loginDetails.email,
  //     password: loginDetails.password,
  //     audience: AUTH0_AUDIENCE,
  //     scope: AUTH0_USER_SCOPE,
  //     client_id: AUTH_CLIENT_ID,
  //     client_secret: AUTH_CLIENT_SECRET, // Only for server-side applications
  //   };

  //   try {
  //     const response = await fetch(
  //       "https://ankitkumar1212.us.auth0.com/oauth/token",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       }
  //     );
  //     console.log("Response-->", response);

  //     if (!response.ok) {
  //       setSignupLoader(false);
  //       throw new Error("Failed to authenticate");
  //     }

  //     const dataFor = await response.json();

  //     // Handle the access token here
  //     setAccessToken(dataFor.access_token);

  //     const expirationDate = new Date();
  //     expirationDate.setDate(expirationDate.getDate() + 30);

  //     // Set only the access_token in the cookie
  //     document.cookie = `access_token=${
  //       dataFor.access_token
  //     }; expires=${expirationDate.toUTCString()}; path=/; secure`;

  //     // Continue with additional logic
  //     const responseOfSignup = await fetch(
  //       `${process.env.REACT_APP_API_URL}api/signup`,
  //       {
  //         headers: {
  //           authorization: `Bearer ${dataFor.access_token}`,
  //         },
  //       }
  //     ).then((res) => res.json());

  //     if (responseOfSignup === true) {
  //       setSignupLoader(false);
  //       navigate("/dashboard");
  //     }
  //   } catch (error) {
  //     setSignupLoader(false);
  //     console.error("Error:", error);
  //   }
  // };

  return (
    <>
      {msg ? (
        <Box
          sx={{
            minHeight: { lg: "30em", md: "26em", sm: "30em", xs: "30em" },
            display: "flex",
            alignItems: "center",
            justifyContent:"center",
            flexDirection:"column"
          }}
        >
          <VerifiedOutlinedIcon
            sx={{
              color: "#33ff33",
              fontSize: "60px",
              borderRadius: "50%",
            }}
          />
          <Box
            sx={
              error || msg
                ? {
                    width: "100%",
                    p: "15px",
                    fontSize: "1.2em",
                    color: msg ? "#5cdd5c" : "#f34646",
                    // color: "white",
                    borderRadius: "5px",
                    textAlign: "center",
                  }
                : null
            }
          >
            {msg && <Box>{msg}</Box>}
          </Box>
        </Box>
      ) : (
        <>
          <h3 style={{ color: "black", opacity: "70%", fontSize: "1.5em" }}>
            Signup
          </h3>
          {isLoading ? (
            <Box
              sx={{
                minHeight: { lg: "28em", md: "26em", sm: "24.5em", xs: "25em" },
                display: "flex",
                alignItems: "center",
           
              }}
            >
              <Loading />
            </Box>
          ) : (
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "1px",
                borderRadius: "2px",
                marginInline: "2.2em",
                gap: "1em",
           
              }}
              onSubmit={handleSubmit}
            >
              <TextField
                label="First Name"
                variant="outlined"
                type="text"
                name="firstName"
                fullWidth
                value={data.firstName}
                required
                onChange={handleChange}
                sx={{ width: "100%" }}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                type="text"
                name="lastName"
                fullWidth
                onChange={handleChange}
                value={data.lastName}
                required
                sx={{ width: "100%" }}
              />
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel>Email</InputLabel>
                <OutlinedInput
                  label="Email"
                  onChange={handleChange}
                  name="email"
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">@dyotis.com</InputAdornment>
                  }
                  onKeyDown={handleKeyDown}
                  value={data.email}
                  required
                />
              </FormControl>
              <FormControl variant="outlined" sx={{ width: "100%" }}>
                <InputLabel>Password</InputLabel>
                <OutlinedInput
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  autoComplete="on"
                  value={data.password}
                  required
                />
              </FormControl>
              <Box
                sx={
                  error || msg
                    ? {
                        width: "90%",
                        p: "15px",
                        m: "5px 0px",
                        fontSize: "14px",
                        bgcolor: msg ? "#5cdd5c" : "#f34646",
                        color: "white",
                        borderRadius: "3px",
                        textAlign: "center",
                      }
                    : null
                }
              >
                {error && <Box>{error}</Box>}
                {msg && <Box>{msg}</Box>}
              </Box>
              <Button
                variant="contained"
                style={{
                  //   backgroundColor: "#1877f2",
                  color: "white",
                  fontSize: "20px",
                  paddingInline: "70px",
                  "&hover": { backgroundColor: "black" },
                  // marginTop: "1.5em",
                }}
                fullWidth
                type="submit"
              >
                Sign Up
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span style={{ color: "black" }}>Have an Account </span>
                <Link to="/auth">
                  <Button
                    sx={{
                      color: "#1877f2",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                  >
                    Login
                  </Button>
                </Link>
              </Box>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default Signup;
