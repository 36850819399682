import React, { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";

const PdfViewer = ({ base64String }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pdfData = atob(base64String);
  const pdfDataUrl = `data:application/pdf;base64,${btoa(pdfData)}`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
    >
      <Viewer fileUrl={pdfDataUrl} onLoadSuccess={onDocumentLoadSuccess} />
    </Worker>
  );
};

export default PdfViewer;
