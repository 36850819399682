import React , { useContext}from "react";
import UserDataTable from "./UserDataTable";
import { Box, } from "@mui/material";

import { DataContext } from "../utils/ContextProider";

const Reports = () => {
  const {isAdmin, CollapsableIcon} = useContext(DataContext);
  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent:{xs:"center",sm:"center",md: CollapsableIcon ? "center":"center", lg: CollapsableIcon ?"center":"center"},
        alignItems: "center",
        pr:{md:".7em" , lg:"0"},
      
        ml:{md: isAdmin  && !CollapsableIcon ? "12em" :"5em" , lg:isAdmin &&!CollapsableIcon ? "11.5em" :"4.5em" },  
    
      }}
    >
      <UserDataTable />
    </Box>
  );
};

export default Reports;
