import React, { useState } from "react";
import ParticlesBg from "./ParticlesBg";
import LoginPage from "./LoginPage";
import Data from "./Data";

const AuthenticationPage = () => {
  return (
    <div>
      <ParticlesBg />
      <LoginPage />
    </div>
  );
};

export default AuthenticationPage;
