import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PrimarySearchAppBar from "./PrimarySearchAppBar";
import List from "./List";
import AddExpense from "./AddExpense";
import { DataContext } from "../utils/ContextProider";
import ThemeSettings from "./ThemeSettings";
import SwipeableTemporaryDrawer from "./SwipeableTemporaryDrawer";
import Sidebar from "./Sidebar";



import { useLocation } from "react-router-dom";
import EnhancedTable from "./EnhancedTable";
import { Stack } from "@mui/material";

const Data = () => {
  const { currentColor, setIsLoading, isLoading, isAdmin, CollapsableIcon,
  } =
    useContext(DataContext);
  const [list, setList] = useState([]);
  const location = useLocation();

  const shouldRenderSidebar = ["/dashboard", "/settings", "/reports"].includes(
    location.pathname
  );

  const checkAccessTokenInCookie = () => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {});
    return cookies.access_token;
  };
  const ACCESS_TOKEN = checkAccessTokenInCookie();

  const fetchData = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/products`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
        },
      }
    );
    const details = await response.json();
    setList(details);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row', lg: 'row' },
        justifyContent: { sm:'space-evenly' ,md:'center'},
        alignItems:"center",
      
        gap: { xs: '.9em', sm: '1em', md:".5em", lg: '2em' },
        mx: { xs: '1em', sm: '2.5em', md: isAdmin ? '2em' :"3.2em" , lg: isAdmin ? '1.5em':"7.5em" },
        // mt: { xs: '1.5em', sm: '2em', md: '3.7em' },
        pt:{ xs: '1.5em', sm: '2em', md: '5.7em' },
        pb: { xs: '7em', sm: '7em', md: '3em' },
        // border: '2px solid red',
        width: { xs: '92vw', sm: '90vw', md: isAdmin? '80vw':"85vw", lg: '81vw', xl:"85vw" },
 
        // backgroundColor: 'yellow'
      
      }}
    >

      <AddExpense list={list} setList={setList} />
      <EnhancedTable list={list} setList={setList} />
    </Box>
  );
};

export default Data;
