import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import GridViewIcon from "@mui/icons-material/GridView";

export const categories = [
  { id: 1, item: "Snacks" },
  { id: 2, item: "Food and Dining" },
  { id: 3, item: "Transportation" },
  { id: 4, item: "Entertainment" },
  { id: 5, item: "Healthcare" },
  { id: 6, item: "Education" },
  { id: 7, item: "Personal Care" },
  { id: 8, item: "Insurance" },
  { id: 8, item: "Investments" },
  { id: 9, item: "Miscellaneous" },
  { id: 10, item: "Others" },
];

export const Themes = [
  {
    backgroundColor: "#222a35",
    buttonColor: "#d95540",
    tableHeadBackgroundColor: "#a6a6a6",
    textColor: "black",
    iconcolor :"gray",
    formBackgroundColor: "#FAF9F6",
  },
  {
    backgroundColor: "#023047",
    buttonColor: "#ffb703",
    tableHeadBackgroundColor: "#faa307",
    textColor: "black",
    formBackgroundColor: "white",
  },
  {
    backgroundColor: "#000814",
    buttonColor: "#415a77",
    tableHeadBackgroundColor: "#778da9",
    textColor: "black",
    formBackgroundColor: "white",
  },
  {
    backgroundColor: "#386641",
    buttonColor: "#4f772d",
    tableHeadBackgroundColor: "#6a994e",
    textColor: "black",
    formBackgroundColor: "white",
  },
];

export const sidebarCategories = [
  { name: "Dashboard", icon: <DashboardIcon sx={{ fontSize: "26px" }} />, url: "/dashboard" },
  { name: "Reports", icon: <BarChartIcon sx={{ fontSize: "26px" }} />, url: "/reports" },
  { name: "Settings", icon: <SettingsIcon sx={{ fontSize: "26px" }} />, url: "/settings" },

];
